import React from "react"
import * as styles from "./About.module.scss"
import Title from "../../components/Title/Title"
import Heading from "../../components/Heading/Heading"
import Paragraph from "../../components/Paragraph/Paragraph"
import Button from "../../components/Button/Button"
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Reveal from "react-reveal/Reveal"

const About = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <section id="about" className={` ${styles.aboutSection} ${"section--bg"}`}>
      <Title type="sectionTitle" className={styles.aboutTitle}>
        {frontmatter.title}
      </Title>
      <Fade left duration="400" fraction={0.5}>
        <img
          src={frontmatter.image}
          alt="About"
          className={styles.backgroundImage}
        />
      </Fade>
      <Fade right duration="400" fraction={0.5} delay="200">
        <div className={styles.block}>
          <Heading className={styles.aboutHeading}>
            {frontmatter.heading}
          </Heading>
          <Paragraph className={styles.aboutParagraph}>
            {frontmatter.description}
          </Paragraph>
          <Button
            className={styles.instagramButton}
            href={frontmatter.buttonLink}
            target="_blank"
          >
            {frontmatter.buttonText}
          </Button>
        </div>
      </Fade>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "about" } }) {
          frontmatter {
            title
            heading
            description
            buttonLink
            buttonText
            image
          }
        }
      }
    `}
    render={data => <About data={data} {...props} />}
  />
)
