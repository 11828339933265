import React from "react"
import * as styles from "./Project.module.scss"
import Modal from "react-modal"
import Lock from "../../../static/assets/images/lock.svg"

Modal.setAppElement("#___gatsby")

const project = ({ heading, subheading, index, openModal }) => (
  <div className={styles.block} onClick={() => openModal(index)}>
    <h2 className={styles.heading}>{heading}</h2>
    <p className={styles.subheading}>{subheading}</p>
    <div className={styles.projectFooter}>
      <p className={styles.link}>View case study</p>
      <span className={styles.lock}>🔐</span>
      {/* <img src={Lock} alt="lock" className={styles.lock} /> */}
    </div>
  </div>
)

export default project
