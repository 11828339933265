import React from 'react';
import * as styles from './Link.module.scss';

const Link = ({ to, children, type, className }) => {
  return (
    <a href={to} className={`${styles[type]} ${className || ''}`}>
      {children}
    </a>
  )
}

export default Link;