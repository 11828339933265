// extracted by mini-css-extract-plugin
export var breakClassName = "Contacts-module--breakClassName--0bb15";
export var contactsHeading = "Contacts-module--contactsHeading--de13b";
export var contactsSection = "Contacts-module--contactsSection--67d14";
export var footer = "Contacts-module--footer--bb5a6";
export var footerLink = "Contacts-module--footerLink--1780d";
export var gallery = "Contacts-module--gallery--31426";
export var hoverEmoji = "Contacts-module--hoverEmoji--f6d02";
export var linkContainer = "Contacts-module--linkContainer--3d202";
export var loading = "Contacts-module--loading--67497";
export var postScriptum = "Contacts-module--postScriptum--4f738";
export var tooltip = "Contacts-module--tooltip--e8919";