// extracted by mini-css-extract-plugin
export var closeButton = "ProjectModal-module--closeButton--2f481";
export var closeButtonContainer = "ProjectModal-module--closeButtonContainer--db9b8";
export var formError = "ProjectModal-module--formError--f57e4";
export var modal = "ProjectModal-module--modal--00db9";
export var modalBody = "ProjectModal-module--modalBody--f11a1";
export var modalBodyHeading = "ProjectModal-module--modalBodyHeading--0d02e";
export var modalBodyOverview = "ProjectModal-module--modalBodyOverview--dece6";
export var modalBodyRole = "ProjectModal-module--modalBodyRole--72dad";
export var modalBodySubheading = "ProjectModal-module--modalBodySubheading--2bc6c";
export var modalButton = "ProjectModal-module--modalButton--e5f80";
export var modalButtons = "ProjectModal-module--modalButtons--ce1f8";
export var modalForm = "ProjectModal-module--modalForm--26ec3";
export var modalHeader = "ProjectModal-module--modalHeader--25dab";
export var modalHeading = "ProjectModal-module--modalHeading--2b033";
export var modalInput = "ProjectModal-module--modalInput--fd502";
export var modalLock = "ProjectModal-module--modalLock--bbdb4";
export var modalPassword = "ProjectModal-module--modalPassword--6b85c";
export var modalSubheading = "ProjectModal-module--modalSubheading--524f7";
export var overlay = "ProjectModal-module--overlay--136e5";
export var windowButton = "ProjectModal-module--windowButton--e8620";
export var windowHeader = "ProjectModal-module--windowHeader--aece1";