// extracted by mini-css-extract-plugin
export var bio = "Introduction-module--bio--fc584";
export var description = "Introduction-module--description--a70ac";
export var fadeBottom = "Introduction-module--fade-bottom--b19e6";
export var gradientBox = "Introduction-module--gradientBox--cc8be";
export var introductionSection = "Introduction-module--introductionSection--29713";
export var leftToRight = "Introduction-module--left-to-right--510e8";
export var leftToRightScale = "Introduction-module--left-to-right-scale--41c21";
export var name = "Introduction-module--name--1caab";
export var profilePicture = "Introduction-module--profilePicture--592c8";
export var rightToLeft = "Introduction-module--right-to-left--1f38f";
export var rightToLeft2 = "Introduction-module--right-to-left-2--07d60";
export var sectionLeft = "Introduction-module--sectionLeft--22462";
export var sectionRight = "Introduction-module--sectionRight--a0b9a";
export var sectionTitle = "Introduction-module--sectionTitle--41f1b";
export var text = "Introduction-module--text--7f642";
export var topToBottom = "Introduction-module--top-to-bottom--7d5e4";