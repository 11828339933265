import React from "react";
import * as styles from "./InformationBlock.module.scss";
import Heading from "../../components/Heading/Heading";
import Paragraph from "../../components/Paragraph/Paragraph";
import Button from "../../components/Button/Button";

const InformationBlock = ({heading, paragraph, buttonText, link}) => {
    return (
        <div className={styles.block}>
            <Heading className={styles.heading}>
                {heading}
            </Heading>
            <Paragraph className={styles.paragraph}>
                {paragraph}
            </Paragraph>
            <Button 
            className={styles.button}
             href={link} target="_blank">{buttonText}</Button>
        </div>
    );
}

export default InformationBlock;