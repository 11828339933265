// extracted by mini-css-extract-plugin
export var linkedInBlock = "Skills-module--linkedInBlock--5045a";
export var linkedInButton = "Skills-module--linkedInButton--ca075";
export var linkedInHeading = "Skills-module--linkedInHeading--c7f8a";
export var sectionLeft = "Skills-module--sectionLeft--47f3c";
export var sectionRight = "Skills-module--sectionRight--48bb8";
export var sectionTitle = "Skills-module--sectionTitle--415f5";
export var sectionWrap = "Skills-module--sectionWrap--b156a";
export var skillsHeading = "Skills-module--skillsHeading--493bf";
export var skillsParagraph = "Skills-module--skillsParagraph--1e7a4";
export var skillsSection = "Skills-module--skillsSection--2229c";